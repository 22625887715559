import Vue from 'vue';
import VueGtag from 'vue-gtag';
import store from '@/store';
import router from '@/router';

if (store.state.analytics_id) {
  Vue.use(VueGtag, {
    config: { id: store.state.analytics_id },
  }, router);
}
