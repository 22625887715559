import Vue from 'vue';
import Vuex from 'vuex';

import state from './state.json';

Vue.use(Vuex);

/** Loads in modules dynamically */
const requireContext = require.context('./modules', false, /.*\.js$/);

const modules = requireContext.keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((moduleFiles, [name, module]) => ({ ...moduleFiles, [name]: module }), {});

/** Exports the whole store */
export default new Vuex.Store({
  state: {
    ...state,
    hasBanner: false,
  },
  modules,
  mutations: {
    SET_BANNER(state, hasBanner) {
      state.hasBanner = hasBanner;
    },
  },
});
