<template>
  <div id="app" :class="appClass">
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Footer from '@/components/Global/Footer.vue';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    ...mapState({
      instanceName: (state) => state.name,
      metaDescription: (state) => state.meta_description,
    }),
    appClass() {
      // Both the ignored route and the directory route share the same class
      if (['ignored', 'directory'].includes(this.$route.name)) {
        return 'directoryPage';
      }
      if (['facet-home', 'directory-home'].includes(this.$route.name)) {
        return 'directory-homePage';
      }

      return `${this.$route.name}Page`;
    },
  },
  components: {
    Footer,
  },
  metaInfo() {
    const title = `${this.instanceName} Directory`;
    const meta = [
      {
        name: 'description',
        content: this.metaDescription,
      },
    ];

    return { title, meta };
  },
};
</script>
